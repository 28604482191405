import gql from 'graphql-tag'

export const whitelistStatus = gql`query whitelistStatus ($dropId: uuid!) {
  whitelists(where: { dropId: { _eq: $dropId } }) {
    id
    quantity
    discordUser
    address
    verified
    purchased
    createdAt
    updatedAt
  }
}`

export const markWhitelistUsed = gql`mutation whitelistUsed($id: uuid!) {
  update_whitelists_by_pk(pk_columns: { id: $id }, _set: { purchased: true }) {
    id
    quantity
    discordUser
    address
    verified
    purchased
    createdAt
    updatedAt
  }
}`

export const whitelistRegister = gql`mutation whitelistRegister ($dropId: uuid!, $quantity: Int!, $discordUser: String!, $address: String!) {
  insert_whitelists_one(object: {
    dropId: $dropId,
    quantity: $quantity,
    discordUser: $discordUser,
    address: $address
  }) {
    id
    quantity
    discordUser
    address
    verified
    purchased
    createdAt
    updatedAt
  }
}`
