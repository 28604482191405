<template>
  <form @submit="save">
    <div class="sm:flex sm:items-start">
      <span class="mx-auto flex-shrink-0 bg-blue-500 flex items-center justify-center h-12 w-12 rounded-lg">
        <KeyIcon class="h-6 w-6 text-white" aria-hidden="true" />
      </span>
      <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900">
          Join the games
        </DialogTitle>
        <div class="mt-1">
          <p class="max-w-2xl text-sm text-gray-500">
            We'll notify you by email at the end of each round, and airdrop your prizes to the provided address.
          </p>
          <div class="py-6">
            <div class="isolate -space-y-px rounded-md shadow-sm text-left">
              <div class="relative border border-gray-300 rounded-md rounded-b-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                <label for="email" class="block text-xs font-medium text-gray-700">Email</label>
                <input type="email" id="email" v-model="profile.email" class="block border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="johndoe@gmail.com" />
              </div>
              <div class="relative border border-gray-300 px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                <label for="discord" class="block w-full text-xs font-medium text-gray-700">Discord username</label>
                <input type="text" id="discord" v-model="data.discordUser" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="johndoe#1234" />
              </div>
              <div class="relative border border-gray-300 rounded-md rounded-t-none px-3 py-2 focus-within:z-10 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600">
                <label for="address" class="block w-full text-xs font-medium text-gray-700">Address</label>
                <input type="text" id="address" v-model="data.address" class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="0x..." />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
      <DBtn type="submit" :loading="loading" color="blue" primary class="w-full text-base sm:ml-3 sm:w-auto">
        Join the games
      </DBtn>
      <button type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:w-auto sm:text-sm" @click="emit('close')">
        Cancel
      </button>
    </div>
  </form>
</template>

<script setup>
/* global _gs */
import { reactive, inject } from 'vue'
import { useStore } from 'vuex'
import { DialogTitle } from '@headlessui/vue'
import { KeyIcon } from '@heroicons/vue/outline'
import { notify } from '@kyvg/vue3-notification'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { updateEmail, ownProfile } from '@/gql/auth'
import { whitelistRegister } from '@/gql/presale'
import { DBtn } from '@ui'

const store = useStore()
const drop = inject('drop')
const emit = defineEmits(['close'])
const props = defineProps({ refetch: Function })

const data = reactive({
  dropId: drop.value.id,
  quantity: 0,
  discordUser: null,
  address: null,
})

const id = store.getters['auth/id']
const { result } = useQuery(ownProfile, { id })
const profile = useResult(result, {
  id,
  email: null,
}, ({ account }) => ({
  id: account.id,
  email: account.email,
}))

const { mutate: saveEmail } = useMutation(updateEmail, () => ({
  variables: profile.value
}))
const { mutate: register, loading } = useMutation(whitelistRegister, () => ({
  variables: data
}))

const save = async (e) => {
  e.preventDefault()

  try {
    await Promise.all([
      saveEmail(),
      register()
    ])
    notify({ type: 'success', text: 'Registered to play' })
    _gs('event', 'Game registration', {
      quantity: data.quantity,
      drop: drop.value.slug
    })
  } catch (err) {
    if (err.message.includes('whitelists_dropId_userId_key')) {
      notify({ type: 'error', title: 'Already registered', text: 'You\'ve already registered for this drop' })
    } else {
      notify({ type: 'error', title: 'Unexpected error', text: err.message })
    }
  }

  await props.refetch()
}
</script>