<template>
  <section aria-labelledby="mailing-list-title">
    <div v-if="!profile.email" class="rounded-lg bg-gradient-to-tr from-gray-900 to-gray-700 overflow-hidden shadow">
      <div class="p-6">
        <h2 class="text-base font-medium text-white" id="mailing-list-title">Don't miss out</h2>
        <div class="mt-2 max-w-xl text-sm text-gray-100">
          <p>
            Add an email to your account to get notifications and important updates. No spam.
          </p>
        </div>
        <form @submit="save" class="mt-6 sm:flex sm:items-center">
          <div class="w-full sm:max-w-xs">
            <label for="email" class="sr-only">Email</label>
            <input id="email" v-model="profile.email" type="email" autocomplete="email" required="" class="w-full text-white bg-white bg-opacity-20 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm" placeholder="you@example.com" />
          </div>
          <button type="submit" class="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
            Update
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { updateEmail, ownProfile } from '@/gql/auth'
import { DBtn } from '@ui'

const store = useStore()
const id = store.getters['auth/id']

const { result } = useQuery(ownProfile, { id })
const profile = useResult(result, {
  id,
  email: null,
}, ({ profile, account }) => ({
  id: account.id,
  email: account.email,
}))

const { mutate, loading } = useMutation(updateEmail, () => ({
  variables: profile.value
}))

const save = async (e) => {
  e.preventDefault()
  try {
    await mutate()
    notify({ type: 'success', text: 'Email saved' })
  } catch (err) {
    if (err.message.includes('accounts_email_key')) {
      notify({ type: 'error', title: 'Invalid email', text: 'Already used by another account' })
    } else {
      notify({ type: 'error', title: 'Unexpected error', text: err.message })
    }
  }
}
</script>