<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-white overflow-hidden shadow-lg">
    <div class="relative z-10 py-8 bg-gradient-to-tr from-blue-500 to-blue-700 sm:py-16 md:py-20 lg:max-w-3xl lg:w-full lg:py-28 xl:max-w-4xl">
      <svg class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-blue-900 transform translate-x-1/2" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
        <polygon points="50,0 100,0 50,100 0,100" />
      </svg>

      <main class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-10 lg:pr-24">
        <div class="sm:text-center lg:text-left">
          <div>
            <span class="inline-flex space-x-4">
              <span class="rounded bg-blue-50 px-2.5 py-1 text-xs font-semibold text-blue-700 tracking-wide uppercase">
                Entry open
              </span>
              <span class="inline-flex items-center text-sm font-medium text-blue-100 space-x-1">
                <span>Join the games by November 1st</span>
              </span>
            </span>
          </div>
          <h1 class="mt-8 text-4xl tracking-tight font-extrabold text-blue-200 sm:text-5xl md:text-6xl">
            <span class="block xl:inline">Welcome to the</span>
            {{ ' ' }}
            <span class="block text-blue-50 xl:inline">Keycard Games</span>
          </h1>
          <p class="mt-3 text-base text-blue-100 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            Free to play, multi-round elimination challenge. Pass each round to get an NFT and entry to the next. Own all 5 at the end to enter a draw to receive some great prizes...
          </p>
          <p class="mt-3 text-base text-blue-100 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
            May the odds be ever in your favour.
          </p>
          <div class="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
            <div>
              <button @click="emit('join')" class="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 md:py-4 md:text-lg md:px-10">
                Join the games
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
      <div class="h-56 w-full object-cover bg-blue-800 flex items-center justify-center relative sm:h-72 md:h-96 lg:w-full lg:h-full">
        <div class="w-full h-full absolute inset-0 opacity-50">
          <img src="@/assets/img/icon/astronaut-square.png" class="object-cover object-top-25 lg:object-center w-full h-full" />
        </div>
        <video class="z-10 w-full h-full lg:w-3/4 lg:h-3/4" muted autoplay loop playsinline>
          <!-- <source src="/static/keycards/output.mov" type='video/quicktime'> -->
          <source src="./img/keycard.webm" type="video/webm">
        </video>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject } from 'vue'
const emit = defineEmits(['join'])
const drop = inject('drop')
</script>