<template>
  <HeroCard @join="joinGames" />

  <div class="max-w-7xl mx-auto">
    <DSidebarLayout sticky class="mt-6">
      <!-- <DiscordCta class="mb-6" /> -->

      <HowToPlayCard />

      <template v-if="breakpoint.mdAndDown">
        <CountdownCard @join="joinGames" />
      </template>

      <PrizesSection @join="joinGames" />

      <div class="relative mt-6 mb-2">
        <div class="absolute inset-0 flex items-center" aria-hidden="true">
          <div class="w-full border-t border-gray-300" />
        </div>
        <div class="relative flex justify-center">
          <span class="px-3 bg-white text-lg font-medium text-gray-900">
            Start playing
          </span>
        </div>
      </div>
      <RoundOneCard />
      <RoundTwoCard />
      <RoundThreeCard />
      <RoundFourCard />
      <RoundFiveCard />

      <template #sidebar>
        <template v-if="breakpoint.lgAndUp">
          <CountdownCard @join="joinGames" />
        </template>
        <SneksPromoCard />
        <MailingListCard />
      </template>
    </DSidebarLayout>
  </div>

  <JoinModal v-model="showModal" />
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { DSidebarLayout, useBreakpoint } from '@ui'

// Main
import HeroCard from './HeroCard.vue'
import DiscordCta from './DiscordCta.vue'
import PrizesSection from './PrizesSection.vue'
import RoundOneCard from './RoundOneCard.vue'
import RoundTwoCard from './RoundTwoCard.vue'
import RoundThreeCard from './RoundThreeCard.vue'
import RoundFourCard from './RoundFourCard.vue'
import RoundFiveCard from './RoundFiveCard.vue'
import HowToPlayCard from './HowToPlayCard.vue'

// Sidebar
import MailingListCard from '@/components/roof/explore/MailingListCard.vue'
import SneksPromoCard from '@/components/roof/games/SneksPromoCard.vue'
import CountdownCard from './CountdownCard.vue'
import JoinModal from './JoinModal.vue'

const store = useStore()
const breakpoint = useBreakpoint()

const showModal = ref(false)
const joinGames = async () => {
  if (!store.getters['auth/id']) {
    console.log('calling dispatch')
    await store.dispatch('auth/loginWithMetamask')  
  }
  
  showModal.value = true
}
</script>