<template>
  <section aria-labelledby="countdown-title">
    <div class="rounded-lg bg-white overflow-hidden shadow">
      <div class="p-6">
        <h2 class="text-base font-medium text-gray-900" id="countdown-title">Games starting in</h2>
        <div class="mt-2 flex items-center space-x-3">
          <h2 class="text-5xl font-extrabold tracking-tight">
            <vue-countdown :time="remaining" v-slot="{ days, hours, minutes, seconds }">
              <template v-if="days">{{ days }}d {{ hours }}h {{ minutes }}m</template>
              <template v-else>
                {{ hours > 9 ? hours : `0${hours}` }}:{{ minutes > 9 ? minutes : `0${minutes}` }}:{{ seconds > 9 ? seconds : `0${seconds}` }}
              </template>
            </vue-countdown>
          </h2>
        </div>
        <DBtn class="mt-4" @click="emit('join')" color="blue" primary lg>
          Join the games
        </DBtn>
      </div>
    </div>
  </section>
</template>

<script setup>
import { inject, computed } from 'vue'
import { DBtn } from '@ui'

const emit = defineEmits(['join'])
const drop = inject('drop')
const remaining = new Date(drop.value.sale.endDate).getTime() - Date.now()
</script>