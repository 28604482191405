<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <JoinStatus v-if="status" :status="status" @close="onClose" />
            <JoinRegister v-else @close="onClose" :refetch="refetch" />
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
import { computed, inject } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { whitelistStatus } from '@/gql/presale'
import JoinRegister from './JoinRegister.vue'
import JoinStatus from './JoinStatus.vue'

// Handle opening and closing
const emit = defineEmits(['update:modelValue'])
const props = defineProps({ modelValue: Boolean })
const open = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val)
})

// Fetch status to render form or status
const drop = inject('drop')
const { result, loading, refetch } = useQuery(whitelistStatus, () => ({ dropId: drop.value.id }))
const status = useResult(result, null, ({ whitelists }) => whitelists[0])

const onClose = () => {
  console.log({ dropId: drop.value.id })
  refetch({ dropId: drop.value.id })
  open.value = false
}
</script>